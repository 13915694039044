import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { UserAuthService } from '@services/auth/user-auth.service';
import { getRouteByName } from '@services/routes/workflow-routes.dto';

export const AuthGuard: CanActivateChildFn = (route, state): boolean => {
  // NOTE: Dejar pasar cuando se visita al detalle de una solicitud, para realizar la redireccion automática
  if (state.url.includes(getRouteByName('detailRequest')!.value)) {
    return true;
  }

  const userAuth = inject(UserAuthService);

  const router = inject(Router);

  const user = userAuth.getUserAuth();

  if (!user) {
    router.navigate(['/login']);
    return false;
  }
  return true;
};
